<style lang="less" scoped>
  .handle-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    img {
      margin-left: 5px;
      height: 25px;
      pointer-events: none;
    }
    span + span {
      margin-left: 5px;
    }
  }
  .handle-block {
    position: relative;
    .action {
      background-color: #FFF;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 5px;
      top: 5px;
      transition: all .3s;
      line-height: 1;
      i { font-size: 18px; cursor: pointer;transition: all .3s; }
      i.active, i:hover { color: #2F5BEA; }
      i + i {
        margin-left: 5px;
      }
      i.icon-a-2 { color: red; }
    }
    &:hover .action {
      pointer-events: auto;
      opacity: 1;
    }
  }
</style>

<template>
  <div class="handle-block">
    <div class="handle-item" v-for="item in data.filter(v => v.handleUserId)" :key="item.id">
      <span v-if="item.content">{{ item.content }}</span>
      <span v-if="item.handleTime">{{ item.handleTime.substr(0, 10) }}</span>
      <template v-if="mode === 'auto'">
        <img @error="onError(item)" v-if="item.handleUserSignFileId" :src="fileUrl({ id: item.handleUserSignFileId, mode: 'preview' })" />
        <span v-else>{{ item.handleUserName }}</span>
      </template>
      <img @error="onError(item)" v-else-if="mode === 'img'" :src="item.handleUserSignFileId ? fileUrl({ id: item.handleUserSignFileId, mode: 'preview' }) : '/static/images/work/steps/1-0.png'">
      <span v-else>{{ item.handleUserName }}</span>
    </div>
    <div class="action">
      <Tooltip style="margin-right: 5px;" v-if="errorList.length" :max-width="300" transfer :delay="500">
        <i class="iconfont icon-a-2"></i>
        <template v-slot:content>
          <div v-for="(item, index) in errorList" :key="index">{{ item.message }}</div>
        </template>
      </Tooltip>
      <i @click="mode = 'auto'" class="iconfont icon-caigou" :class="{ active: mode === 'auto' }"></i>
      <i @click="mode = 'text'" class="iconfont icon-field" :class="{ active: mode === 'text' }"></i>
      <i @click="mode = 'img'" class="iconfont icon-m-qianming" :class="{ active: mode === 'img' }"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Array, default: () => ([]) }
  },
  data () {
    return {
      mode: 'auto',
      errorList: []
    }
  },
  computed: {
    fileUrl () {
      return this.$store.getters.fileUrl
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.errorList = []
      }
    }
  },
  methods: {
    onError (item) {
      this.errorList.push(item.handleUserName + '签名加载失败')
    }
  },
  mounted () {
    this.$store.dispatch('loadFileToken')
  }
}
</script>
